import { Fragment, useEffect, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { FcImport, FcKey, FcNext, FcSearch } from "react-icons/fc";
import { MemberRegisterForm, MemberPoint, MemberChangePw } from ".";

const menus = [
  {
    tab: 1,
    id: "register",
    name: "회원가입",
    icon: <FcImport />,
    classList: "register",
  },
  {
    tab: 2,
    id: "point",
    name: "포인트 조회",
    icon: <FcSearch />,
    classList: "point",
  },
  {
    tab: 3,
    id: "password",
    name: "비밀번호 변경",
    icon: <FcKey />,
    classList: "",
  },
];

const MemberAccount = () => {
  const params = new URLSearchParams(location.search);
  const stCode = params.get("st_code");
  const custName = params.get("cust_name");

  const storageUrl = localStorage.getItem("acc_url");

  const navigate = useNavigate();

  const [resStCode, setResStCode] = useState("");

  // 회원가입 체크
  const [successRegister, setSuccessRegister] = useState(false);

  useEffect(() => {
    if (stCode) {
      setResStCode(JSON.parse(JSON.stringify(stCode)));
    }
  }, [stCode]);

  const [menuName, setMenuName] = useState("");

  // 선택 된 탭 useState
  const userPath = location.pathname.replace("/account/", "");
  const [accountCurrentTab, setAccountCurrentTab] = useState(() => {
    const menuTab = menus.find((menu) => menu.id === userPath);
    return menuTab ? menuTab.tab : 0;
  });

  // 메뉴 컴포넌트
  const renderContent = (tab) => {
    switch (tab) {
      case 1:
        return (
          <MemberRegisterForm
            stCode={resStCode}
            successRegister={successRegister}
            setSuccessRegister={setSuccessRegister}
          />
        );
      case 2:
        return <MemberPoint stCode={resStCode} />;
      case 3:
        return <MemberChangePw stCode={resStCode} />;
    }
  };

  // 메뉴 클릭
  const handleMenuClick = (menuNo) => {
    setAccountCurrentTab(menuNo);
  };

  // 탭 변경에 따른 경로 변경
  useEffect(() => {
    if (accountCurrentTab >= 1) {
      const currentMenu = menus.find((menu) => menu.tab === accountCurrentTab);
      navigate(`${currentMenu.id}`);
    }
  }, [accountCurrentTab]);

  // 경로 변경에 따른 탭 상태 변경
  useEffect(() => {
    const basePath = "/account";
    if (location.pathname === `${basePath}`) {
      setAccountCurrentTab(0);
      setMenuName("");
      localStorage.setItem("acc_url", location.href);
    } else {
      const pathname = location.pathname.replace(`${basePath}/`, "");
      const getMenu = menus.find((menu) => menu.id === pathname);
      setAccountCurrentTab(getMenu.tab);
      setMenuName(getMenu.name);
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <div
        className={`register-body flex-column ${
          accountCurrentTab === 1 && !successRegister
            ? "register-form-selected"
            : ""
        }`}
      >
        {accountCurrentTab > 0 && (
          <div
            className="d-flex align-items-center goback_div w-100"
            onClick={() => {
              storageUrl ? (location.href = storageUrl) : history.back();
            }}
          >
            <BsChevronLeft className="back-icon mr-3" />
            <span>{menuName}</span>
          </div>
        )}
        {accountCurrentTab === 0 && (
          <div className="title text-center mb-4 w-80">
            <h4 className="mb-4">
              <b>{custName}</b>
            </h4>
            {menus.length > 0 && (
              <div className="w-100">
                {menus.map((menu, i) => {
                  return (
                    <div
                      key={menu.tab}
                      id={menu.id}
                      className={`${menu.classList} register_tag d-flex justify-content-sm-between align-items-center`}
                      onClick={() => handleMenuClick(i + 1)}
                    >
                      <div className="d-flex align-items-center menu-icon">
                        {menu.icon}
                        <span>{menu.name}</span>
                      </div>
                      <FcNext className="next-icon" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <Routes>
          {menus.map((menu) => (
            <Route
              key={menu.id}
              path={`${menu.id}`}
              element={renderContent(accountCurrentTab)}
            />
          ))}
        </Routes>
      </div>
    </Fragment>
  );
};

export default MemberAccount;
