import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  getFormattedDateHyphen,
  handleKeyPress,
  korRegularExpFnc,
  phoneRemoveHyphen,
  valiadateContact,
} from "../../../../../common/utils/FunctionUtility";

import "../../../../../assets/css/member-register.scss";
import TelInput from "../../../../../common/utils/components/Telinput";

const MemberRegisterForm = ({
  stCode,
  successRegister,
  setSuccessRegister,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: "",
      contact: "",
      gender: "M",
      password: "",
      agreement: false,
    },
  });

  // 오토 포커스
  // 포커스는 되는데 모바일 보안 정책상 키보드가 안뜸
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById("contact");
      if (!input) return;
      input.click();
      input.focus();
      setFocus("contact", { shouldSelect: true }); // 안정적 포커스 설정

      // 가상 키보드 api 이지만 안드로이드에서도 작동 안함
      if ("virtualKeyboard" in navigator) {
        navigator.virtualKeyboard.show();
      }
    }, 500);
  }, [setFocus]);
  // query params 중 st_code, cust_name 가져오기
  const queryParams = new URLSearchParams(window.location.search);
  const st_code = queryParams.get("st_code");
  const cust_name = queryParams.get("cust_name");
  // 초기 접속시 st_code, cust_name이 있을 경우 localStorage에 저장
  useEffect(() => {
    if (st_code && cust_name) {
      localStorage.setItem(
        "acc_url",
        location.origin +
          "/account?st_code=" +
          st_code +
          "&cust_name=" +
          cust_name
      );
      localStorage.setItem("acc_st_code", st_code);
    }
  }, []);
  const getStCode = localStorage.getItem("acc_st_code");

  const [showPassword, setShowPassword] = useState(false);

  const getCustName = () => {
    const acc_url = localStorage.getItem("acc_url");
    if (acc_url) {
      const url = new URL(acc_url);
      return url.searchParams.get("cust_name");
    }
  };

  // 가입 클릭
  const handleRegisterMember = async (data) => {
    data.st_code = getStCode;

    // 비밀번호가 설정 되지 않은 경우 휴대폰 뒷자리 4자리로 설정
    if (!data.password) {
      const getPw = data.contact.substring(9, data.contact.length);
      data.password = getPw;
    }

    data.contact = phoneRemoveHyphen(data.contact);

    const valiadate = await valiadateContact({
      st_code: getStCode,
      contact: data.contact,
    });
    if (valiadate) {
      registerMember(data);
    } else {
      alert("이미 가입된 연락처입니다!");
    }
  };

  // 등록
  const registerMember = (data) => {
    fetch(`/api/member/reg/qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setSuccessRegister(true);
        } else {
          errorMsg(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
        alert("가입에 실패하였습니다. 다시 시도해주세요.");
      });
  };

  // 에러메시지 표시
  const errorMsg = (message) => {
    switch (message) {
      case "INVALID_PASSWORD_FORMAT":
        alert("비밀번호 형식이 맞지 않습니다. 4자리로 입력해주세요.");
        break;
      default:
        alert("가입에 실패하였습니다. 다시 시도해주세요.");
        break;
    }
  };

  useEffect(() => {
    if (stCode) {
      localStorage.setItem("acc_st_code", stCode);
    }

    // return () => {
    //   localStorage.removeItem("acc_st_code");
    //   localStorage.removeItem("acc_url");
    // };
  }, []);

  return (
    <Fragment>
      {successRegister ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>
            <b style={{ fontSize: "1.5rem" }}>가입이 완료되었습니다!</b>
          </h5>
          <button
            className="btn btn-primary mt-4"
            // 기존에는 history.back 였으나 회원가입 메인 화면으로 가게 수정 왜냐면 바로 이페이지로 접근가능
            onClick={() => (location.href = localStorage.getItem("acc_url"))}
            style={{ fontSize: "1.2rem" }}
          >
            메인으로
          </button>
        </div>
      ) : (
        <div className="register-container mt-4 mb-4 relative register-main-container">
          <form
            className="form"
            onSubmit={handleSubmit(handleRegisterMember)}
            onKeyPress={(e) => handleKeyPress(e)}
          >
            <h4
              className="mt-3 mb-4 text-center notice"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                color: "#FFF",
              }}
            >
              <span className="text">{getCustName()}</span>
            </h4>
            <div className="required-form">
              <span className="required">*필수입력</span>
              <div className="mt-3 mb-3 tel-area">
                <div className="register-form-input mb-0">
                  <span className="tel-text">연락처</span>
                  <TelInput
                    id="contact"
                    type="tel"
                    placeholder="010-0000-0000"
                    required
                    {...register("contact", {
                      validate: (value) =>
                        phoneRemoveHyphen(value).length == 11 ||
                        "연락처를 정확하게 입력해주세요.",
                    })}
                  />
                </div>
                {errors.contact && (
                  <span className="text-danger">
                    <small>{errors.contact.message}</small>
                  </span>
                )}
              </div>
            </div>
            <div className="mt-3 optional-form">
              <span className="optional mb-3">*선택입력</span>
              <div className="password-area">
                <span>비밀번호</span>
                <div
                  className="register-form-input d-flex password"
                  style={{ marginBottom: "0" }}
                >
                  <input
                    id="password"
                    type="number"
                    inputMode="numberic"
                    pattern={/[0-9]*/}
                    className={showPassword ? "" : "password-show-type"}
                    style={{ width: "91%" }}
                    placeholder="비밀번호 입력"
                    onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
                    onInput={(e) => {
                      if (
                        e.currentTarget.value.length > e.currentTarget.maxLength
                      )
                        e.currentTarget.value = e.currentTarget.value.slice(
                          0,
                          e.currentTarget.maxLength
                        );
                    }}
                    maxLength={4}
                    {...register("password")}
                  />
                  <label
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-pw-label"
                  >
                    {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                  </label>
                </div>
                <p className="ml-2 mt-1">
                  <small>비밀번호는 이후 포인트 조회 시 필요합니다.</small>
                </p>
                <p className="ml-2">
                  <small>비밀번호는 4자리로 입력해주세요.</small>
                </p>
                <p className="ml-2" style={{ wordBreak: "keep-all" }}>
                  <small style={{ fontSize: "85%", color: "red" }}>
                    초기 비밀번호는 휴대폰 뒷자리 4자리로 설정됩니다.
                  </small>
                </p>
              </div>
              <div className="register-form-input mt-4 name-area">
                <span>
                  <small className="text-red-500"></small>이름
                </span>
                <input
                  id="name"
                  type="text"
                  placeholder="이름"
                  {...register("name")}
                />
              </div>
              <div className="register-form-input mt-4 birth-area">
                <span>생년월일</span>
                <br />
                <input
                  id="birthdate"
                  type="date"
                  max={getFormattedDateHyphen()}
                  {...register("birthdate")}
                />
              </div>
              <div className="mt-4 mb-4 gender-area">
                <span>성별</span>
                <div className="mt-2 d-flex align-items-center">
                  {["M", "W"].map((gender) => {
                    return (
                      <label
                        key={gender}
                        style={{
                          marginLeft: "0.8rem",
                          marginRight: gender === "M" ? "3rem" : "",
                        }}
                      >
                        <input
                          type="radio"
                          name="gender"
                          value={gender}
                          className="mr-2"
                          {...register("gender")}
                        />
                        {gender === "M" ? "남" : "여"}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="info-area">
              <div className="info-collect-agreement">
                <small>
                  <b style={{ textDecoration: "underline" }}>
                    개인정보 수집 및 이용 동의
                  </b>
                </small>
                <ul className="mt-2">
                  <li type="none">
                    <small>
                      <b>1. 수집목적: </b> 쿠폰과 포인트 적립 서비스 제공
                    </small>
                  </li>
                  <li type="none">
                    <small>
                      <b>2. 수집항목</b>
                    </small>
                    <li type="circle" className="ml-4">
                      <small>필수 항목 - 연락처</small>
                    </li>
                    <li type="circle" className="ml-4">
                      <small>선택 항목 - 이름, 생년월일, 성별, 비밀번호</small>
                    </li>
                  </li>
                </ul>
                <div className="mb-4">
                  <label className="d-flex align-items-center mt-2">
                    <input
                      type="checkbox"
                      name="agree"
                      className="mr-2"
                      {...register("agreement", {
                        validate: (value) =>
                          value || "개인정보 수집 및 이용에 동의해 주세요.",
                      })}
                    />
                    <small>동의합니다.</small>
                  </label>
                  {errors.agreement && (
                    <small>
                      <span className="text-danger">
                        {errors.agreement.message}
                      </span>
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="register-footer text-center mb-2">
              <button type="submit" className="btn btn-primary">
                가입하기
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export { MemberRegisterForm };
