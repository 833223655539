import React, { forwardRef, useState } from "react";
import { korRegularExpFnc } from "../FunctionUtility";

const formatPhoneNumber = (value) => {
  // 숫자만 남기기
  value = value.replace(/\D/g, "");

  // 010으로 시작하지 않는 번호는 처리하지 않음
  if (!value.startsWith("010")) {
    return value;
  }

  // 010-XXXX-XXXX 형식으로 변환
  if (value.length <= 7) {
    return value.replace(/(\d{3})(\d{1,4})/, "$1-$2");
  } else {
    return value.replace(/(\d{3})(\d{4})(\d{1,4})/, "$1-$2-$3");
  }
};

const TelInput = forwardRef(({ onChange, type, ...rest }, ref) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    let formattedValue = formatPhoneNumber(e.target.value); // 입력 중에도 하이픈 유지
    setInputValue(formattedValue);
    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: formattedValue,
        },
      });
    }
  };

  return (
    <input
      ref={ref}
      type="text"
      inputMode="numeric"
      maxLength={13} // 010-0000-0000 최대 길이
      value={inputValue}
      onChange={handleChange}
      //   onKeyUp={(e) => {
      //     // inputmode가 decimal이 아닐 경우 . 없애기
      //     korRegularExpFnc(e.currentTarget);
      //   }}
      {...rest}
    />
  );
});

export default TelInput;
