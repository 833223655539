import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import { BarLoader } from "react-spinners";
import * as XLSX from "xlsx";
import ExcelDownload from "../../../../../common/utils/excel/ExcelDownload";
import {
  getFormattedDateHyphenGetDate,
  phoneRegularExpFnc,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";

const MemberExcelUpload = (props) => {
  const { isShowUserCallbackAlert, setIsShowUserCallbackAlert } =
    useComponentsContext();

  const [numberOfPeople, setNumberOfPeople] = useState(0);

  const [fileUploading, setFileUploading] = useState(false);
  const [isShowExistMember, setIsShowExistMember] = useState(false);

  const [excelAlert, setExcelAlert] = useState(false);

  const [excelMemberInfos, setExcelMemberInfos] = useState([]);
  const [existMemberList, setExistMemberList] = useState([]);
  const [successRegister, setSuccessRegister] = useState(false);

  const fileInputRef = React.createRef(); // 파일 입력 요소를 참조하기 위한 ref

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // 파일 선택 및 업로드 처리 함수
  const handleFileUpload = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        setFileUploading(true);
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, {
          type: "binary",
          cellDates: true,
          dateNF: "yyyy-mm-dd",
        });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          raw: true,
        });
        // 데이터 검증
        if (validateData(jsonData)) {
          memberExistValidata(jsonData);
        } else {
          setFileUploading(false);
          alert("올바른 형식이 아닙니다. 양식을 확인해 주세요.");
          fileInputRef.current.value = null;
        }
      } catch (error) {
        setFileUploading(false);
        error("파일을 읽는 중 오류가 발생했습니다.\n다시 시도 해주세요.");
        fileInputRef.current.value = null;
      }
    };

    reader.readAsBinaryString(file);
  };

  // 데이터 검증 함수
  const validateData = (data) => {
    const expectedHeaders = [
      "No",
      "이름 (한글)",
      "연락처 (숫자)",
      "성별 (남/여)",
      "생년월일 (YYYY-MM-DD)",
      "등급",
      "보유포인트 (숫자)",
      "방문횟수 (숫자)",
    ];
    const headers = data[2] || [];

    // 헤더 검증
    if (Object.keys(headers).length !== expectedHeaders.length) {
      return false;
    }
    for (let i = 0; i < expectedHeaders.length; i++) {
      if (Object.values(headers)[i] !== expectedHeaders[i]) {
        return false;
      }
    }

    return true;
  };

  // 백엔드에 엑셀 회원 리스트 전송
  const sendExcelMemberList = async (param) => {
    param.member_list.map((member) => {
      switch (member.gender) {
        case "남":
          member.gender = "M";
          break;
        case "여":
          member.gender = "W";
          break;
        default:
          member.gender = "N";
          break;
      }
    });
    return await fetch(`/api/member/reg/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          return res.data;
        } else {
          alert("파일을 읽는 중 오류가 발생했습니다.\n다시 시도 해주세요.");
          fileInputRef.current.value = null;
        }
      })
      .catch((error) => {
        setFileUploading(false);
        console.error(error);
        alert("파일을 읽는 중 오류가 발생했습니다.\n다시 시도 해주세요.");
      });
  };

  // 회원 존재 리스트 확인
  const memberExistValidata = async (jsonData) => {
    const getDatas = jsonData.filter((data, i) => i >= 4 && data["2"]);
    const excelInfos = [];

    getDatas.map((member) => {
      excelInfos.push({
        name: member["1"] ? member["1"] : "",
        contact: member["2"],
        gender: member["3"] ? member["3"] : "N",
        birthdate: member["4"]
          ? getFormattedDateHyphenGetDate(member["4"])
          : "",
        grade: member["5"],
        remain_point: member["6"],
        visit_cnt: member["7"],
      });
    });

    setExcelMemberInfos(excelInfos);
    setFileUploading(false);
  };

  // 확인 버튼 클릭
  const handleRegisterMember = () => {
    if (successRegister || isShowExistMember) {
      window.location.reload();
      return;
    }

    if (excelMemberInfos.length === 0) {
      alert("등록 할 회원이 없습니다.");
    } else {
      // 띄울 알림 종류 선택
      setIsShowUserCallbackAlert(true);
      setExcelAlert(true);
    }
  };

  // Alert 콜백함수 설정
  const noticeFunction = async () => {
    setFileUploading(true);

    const validateMemberList = await sendExcelMemberList({
      st_code: setToAdminTransAcc().stCode,
      member_list: excelMemberInfos,
    });

    // 에러 메시지 키 추가
    validateMemberList.contact_error.map((list) => {
      list.reason = "contact_error";
    });
    validateMemberList.value_error.map((list) => {
      list.reason = "value_error";
    });

    const existMember = validateMemberList.contact_error.concat(
      validateMemberList.value_error
    );

    setExcelMemberInfos([]);

    setExistMemberList(existMember);

    setNumberOfPeople(validateMemberList.insert_size);

    setFileUploading(false);

    if (existMember.length > 0) {
      setIsShowExistMember(true);
    } else {
      setSuccessRegister(true);
    }
  };

  // 제외 사유 메시지 표시
  const errorMsg = (error) => {
    if (error === "contact_error") {
      return "이미 존재하는 회원입니다.";
    } else if (error === "value_error") {
      return "형식에 맞지 않게 입력되었습니다.";
    }
  };

  useEffect(() => {
    if (!props.isShowExcelModal) {
      setIsShowExistMember(false);
      setExcelMemberInfos([]);
      setExistMemberList([]);
      setNumberOfPeople(0);
    }
  }, [props.isShowExcelModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowExcelModal}
        className={`member-excel-modal ${
          existMemberList.length > 2 ? "resize-mod-modal" : "mod-modal"
        }`}
      >
        {fileUploading && (
          <div className="d-flex justify-content-center flex-column align-items-center loading-div upload-loading">
            <span>파일 업로드 중입니다.</span>
            <br />
            <BarLoader color="#5a9bb5" cssOverride={override} size={60} />
          </div>
        )}
        <Modal.Header>
          <Modal.Title>회원 일괄 등록</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowExcelModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <Modal.Body className="usermng-modal-body">
          <div className="order-table ov-h c-table">
            <b>
              아래 양식을 다운받아 회원 정보를 작성 후, 해당 파일을
              업로드해주세요.
            </b>
            <table
              className="info-table modify-table usermng-table"
              style={{ width: "40rem" }}
            >
              <tbody>
                <tr>
                  <th style={{ width: "9rem" }}>엑셀 양식</th>
                  <td style={{ padding: "0.4rem 0.7rem" }}>
                    <div className="d-flex justify-content-sm-between align-items-center">
                      <ExcelDownload
                        className="btn btn-primary"
                        style={{
                          padding: "0.2rem 0.58rem",
                          height: "fit-content",
                        }}
                        menu={"member"}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "6rem" }}>파일 업로드</th>
                  <td>
                    <input
                      type="file"
                      className="col-lg-12 pl-0"
                      accept=".xlsx, .xls"
                      onChange={(e) => handleFileUpload(e)}
                      ref={fileInputRef} // ref를 파일 입력 요소에 연결
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          {excelMemberInfos.length > 0 && (
            <div className="d-flex align-items-center flex-column">
              <div className="w-100">
                <p className="text-center" style={{ marginLeft: "4rem" }}>
                  ※ 아래 파일로 일괄 등록하시겠습니까?
                  <div className=" float-right text-right">
                    총 건수 : {excelMemberInfos.length}
                  </div>
                </p>
              </div>
              <div
                className="d-flex mt-2 flex-column"
                style={{
                  height: excelMemberInfos.length > 7 ? "20rem" : "",
                  overflowY: "scroll",
                }}
              >
                <table
                  className="table text-center fontS add-table mt-2 mb-2"
                  style={{ width: "45rem" }}
                >
                  <thead className="thead-dark">
                    <tr>
                      <th style={{ width: "150px" }}>이름</th>
                      <th style={{ width: "150px" }}>연락처</th>
                      <th style={{ width: "40px" }}>성별</th>
                      <th style={{ width: "150px" }}>생년월일</th>
                      <th style={{ width: "150px" }}>등급</th>
                      <th style={{ width: "90px" }}>보유포인트</th>
                      <th style={{ width: "90px" }}>방문횟수</th>
                    </tr>
                  </thead>
                  <tbody>
                    {excelMemberInfos.length > 0 &&
                      excelMemberInfos.map((excel, i) => {
                        return (
                          <tr key={i}>
                            <td>{excel.name}</td>
                            <td>{phoneRegularExpFnc(excel.contact)}</td>
                            <td>
                              {excel.gender === "N"
                                ? "-"
                                : excel.gender === "남" || excel.gender === "M"
                                ? "남"
                                : "여"}
                            </td>
                            <td>{excel.birthdate}</td>
                            <td>{excel.grade}</td>
                            <td>{excel.remain_point}</td>
                            <td>{excel.visit_cnt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {isShowExistMember && (
            <div className="d-flex align-items-center flex-column">
              <p>
                ※ 등록 과정에서 아래 회원은 이미 등록된 회원이거나 형식에 맞지
                않게 입력되어 제외되었습니다.
              </p>
              <div className="mt-2 float-right text-right">
                총 건수 : {existMemberList.length}
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  height: existMemberList.length > 7 ? "20rem" : "",
                  overflowY: "scroll",
                }}
              >
                <table
                  className="table text-center fontS add-table mt-2 mb-2"
                  style={{ width: "39rem" }}
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>이름</th>
                      <th>연락처</th>
                      <th>제외 사유</th>
                    </tr>
                  </thead>
                  <tbody>
                    {existMemberList.length > 0 &&
                      existMemberList.map((exist, i) => {
                        return (
                          <tr key={i}>
                            <td>{exist.name}</td>
                            <td>{phoneRegularExpFnc(exist.contact)}</td>
                            <td>{errorMsg(exist.reason)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <p>
                위 회원(들)을 제외한 총 {numberOfPeople}명의 회원이 등록
                되었습니다.
              </p>
            </div>
          )}
          {successRegister && (
            <h5>총 {numberOfPeople}명의 회원이 등록 되었습니다.</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleRegisterMember}>
            확인
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className="btn btn-secondary"
            onClick={() => props.setIsShowExcelModal(false)}
          >
            취소
          </button>
        </Modal.Footer>
      </Modal>
      {isShowUserCallbackAlert &&
        excelAlert &&
        UserCallbackAlert(
          "회원을 일괄 등록하시겠습니까?",
          [],
          "확인",
          true,
          "notice",
          function () {
            noticeFunction();
          }
        )}
    </Fragment>
  );
};

export { MemberExcelUpload };
