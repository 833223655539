import { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useComponentsContext } from "../../../../ContextProvider";
import InputTag from "../../../../../common/utils/components/InputTag";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";
import {
  getFormattedDateHyphen,
  handleKeyPress,
  phoneRegularExpFnc,
  phoneRemoveHyphen,
  setToAdminTransAcc,
  valiadateContact,
} from "../../../../../common/utils/FunctionUtility";

const MemberInfoModifyModal = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
  } = useComponentsContext();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const formDefaultValues = {
    name: "",
    contact: "",
    birthdate: "",
    gender: "N",
  };

  const [receiveExistCheck, setReceiveExistCheck] = useState(false);

  const [buttonTxt, setButtonTxt] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");

  // 저장 버튼 클릭
  const handleModifyInfo = async (data) => {
    const contact = phoneRemoveHyphen(data.contact);
    const valiadate = await valiadateContact({
      st_code: setToAdminTransAcc().stCode,
      contact: contact,
    });

    if (!receiveExistCheck && !valiadate) {
      alert("이미 가입된 회원입니다.");
      return;
    }

    let type = receiveExistCheck ? "수정" : "등록";
    data.st_code = setToAdminTransAcc().stCode;
    data.contact = contact;
    if (data.gender === "") data.gender = "N";
    setUserRequestDatas(data);

    // 띄울 알림 종류 선택
    setButtonTxt(type);
    setNoticeTitle(`회원 정보를 ${type}하시겠습니까?`);
    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    let controlByType = buttonTxt === "등록" ? "reg" : "update";
    setUserRestStep({ key: 20, control: controlByType });
    setButtonTxt("");
  };

  // 선택한 회원이 있는 경우에만 조회
  useEffect(() => {
    if (Object.keys(props.selectedMemberInfos).length > 0) {
      props.selectedMemberInfos.contact = phoneRegularExpFnc(
        props.selectedMemberInfos.contact
      );
      reset(props.selectedMemberInfos);
      setReceiveExistCheck(true);
    }
  }, [props.selectedMemberInfos]);

  // 모달이 닫힌 경우 alert 비활성화 및 form, 선택한 회원 정보 리셋
  useEffect(() => {
    if (!props.isShowMemberInfoModal) {
      props.setSelectedMemberInfos({});
      setReceiveExistCheck(false);
      setNoticeAlert(false);
      reset(formDefaultValues);
    }
  }, [props.isShowMemberInfoModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowMemberInfoModal}
        onHide={() => {
          props.setIsShowMemberInfoModal(false);
        }}
        className="mod-modal"
      >
        <Modal.Header>
          <Modal.Title>회원정보관리</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowMemberInfoModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="usermng-modal-body">
            <div className="order-table ov-h c-table">
              <table className="usermng-mod-table">
                <tbody>
                  <tr>
                    <th style={{ width: "9rem" }}>회원명</th>
                    <td>
                      <input
                        id="name"
                        type="text"
                        className="form-control col-lg-12"
                        placeholder="회원명"
                        {...register("name")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "6rem" }}>연락처</th>
                    <td>
                      <InputTag
                        id="contact"
                        type="tel"
                        className="form-control col-lg-12"
                        placeholder="연락처"
                        required
                        readOnly={receiveExistCheck}
                        reset-value="contact"
                        setValue={setValue}
                        {...register("contact", {
                          validate: (value) =>
                            phoneRemoveHyphen(value).length === 11 ||
                            "연락처를 정확하게 입력해주세요.",
                        })}
                      />
                      {errors.contact && (
                        <span className="text-danger">
                          {errors.contact.message}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "6rem" }}>생년월일</th>
                    <td>
                      <input
                        id="birthdate"
                        type="date"
                        className="form-control col-lg-12"
                        max={getFormattedDateHyphen()}
                        {...register("birthdate")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "6rem" }}>성별</th>
                    <td>
                      <div className="d-flex">
                        {["M", "W", "N"].map((gender, i) => {
                          return (
                            <label
                              key={i}
                              className={
                                gender === "N" ? "col-lg-5" : "col-lg-4"
                              }
                            >
                              <input
                                value={gender}
                                type="radio"
                                name="gender"
                                {...register("gender")}
                              />
                              {gender === "M"
                                ? "남"
                                : gender === "W"
                                ? "여"
                                : "미입력"}
                            </label>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              저장
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowMemberInfoModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { MemberInfoModifyModal };
